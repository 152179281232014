import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import ROUTE_URLS from "../core/routes";

interface RouteAuthGuardProps {
  children: ReactNode;
}

const RouteAuthGuard: React.FC<RouteAuthGuardProps> = ({ children }) => {
  const currentUser = localStorage.getItem("currentUser");

  if (!currentUser) {
    return <Navigate to={ROUTE_URLS.LOGIN} replace />;
  }

  return <>{children}</>;
};

export default RouteAuthGuard;
