import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HomeIcon from '@mui/icons-material/Home';
import MessageIcon from '@mui/icons-material/Message';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';

interface SidebarProps {
    onMenuItemClick: (menuItem: any) => void;
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
}

interface User {
    KAM: string;
    username: string;
    phoneNumber: string;
    role: string; // Add role field
}

function Sidebar({ onMenuItemClick, isSidebarOpen, toggleSidebar }: SidebarProps) {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [userInfo, setUserInfo] = useState<User | null>(null);

    useEffect(() => {
        const userInfoFromStorage = localStorage.getItem('currentUser');
        if (userInfoFromStorage) {
            const user = JSON.parse(userInfoFromStorage);
            setCurrentUser(user);
            setUserInfo(user);
        }
    }, []);

    const menuItems = [
        { text: 'Home', path: "/", icon: <HomeIcon /> },
        { text: 'Place an Order', path: "/orders", icon: <MessageIcon /> },
        // { text: 'Order Status', path: "/order-status", icon: <LocalShippingIcon /> },
        // { text: 'Demo', path: "/demo", icon: <LocalShippingIcon /> },
        ...((userInfo?.role === 'SUPER_ADMIN' || userInfo?.role === 'KAM') ? [{ text: 'Users', path: "/users", icon: <PersonIcon /> }] : []),
    ];

    const handleLogout = () => {
        localStorage.removeItem('currentUser');
        navigate('/login');
    };

    return (
        <Box
            sx={{
                width: isSidebarOpen ? 220 : 60,
                bgcolor: '#111827',
                color: '#fff',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                transition: 'width 0.3s ease',
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
                borderBottom: '1px solid #3e454d'
            }}>
                {/* Top bar with menu toggle */}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1
                }}>
                    <AccountCircleIcon sx={{ mr: 1 }} />
                    {isSidebarOpen && currentUser && (
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: '#fff',
                                fontWeight: 'bold'
                            }}
                        >
                            {currentUser.username}
                        </Typography>
                    )}
                </Box>

                {/* KAM details */}
                {isSidebarOpen && currentUser && (
                    <Box>
                        <Typography
                            variant="caption"
                            sx={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                display: 'block',
                                mt: 0.5
                            }}
                        >
                            ID: {currentUser.phoneNumber}
                        </Typography>
                    </Box>
                )}
            </Box>

            <List sx={{ flexGrow: 1, mt: 1 }}>
                {menuItems.map((item, index) => (
                    <ListItem
                        component="div"
                        key={index}
                        sx={{
                            color: '#fff',
                            cursor: 'pointer',
                            '&:hover': {
                                bgcolor: 'rgba(255, 255, 255, 0.1)',
                            }
                        }}
                        onClick={() => onMenuItemClick(item)}
                    >
                        <ListItemIcon sx={{ color: '#fff', minWidth: '40px' }}>
                            {item?.icon}
                        </ListItemIcon>
                        {isSidebarOpen && (
                            <ListItemText
                                primary={item?.text}
                                primaryTypographyProps={{
                                    sx: {
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                    },
                                }}
                            />
                        )}
                    </ListItem>
                ))}
            </List>

            <Divider sx={{ bgcolor: '#3e454d' }} />

            <List>
                <ListItem
                    component="div"
                    sx={{
                        color: '#fff',
                        cursor: 'pointer',
                        '&:hover': {
                            bgcolor: 'rgba(255, 255, 255, 0.1)',
                        }
                    }}
                    onClick={handleLogout}
                >
                    <ListItemIcon sx={{ color: '#fff', minWidth: '40px' }}>
                        <LogoutIcon />
                    </ListItemIcon>
                    {isSidebarOpen && <ListItemText primary="Logout" />}
                </ListItem>
            </List>
        </Box>
    );
}

export default Sidebar;
