export const BASE_URL = "/";
const ROUTE_URLS = {
  HOME: BASE_URL,
  LOGIN: "/login",
  ORDERS: "/orders",
  ORDER_STATUS: "/order-status", 
  USERS: "/users", 
  AG_DEMO: 'ag-demo'
};

export default ROUTE_URLS;
