import { User } from "../type/user";

export const userDatabase: User[] = [
    { KAM: 'Deepak', phoneNumber: '7032303976', password: 'Deepak@123' },
    { KAM: 'Joshi', phoneNumber: '9391801894', password: 'Joshi@123' },
    { KAM: 'Sumith', phoneNumber: '9391801897', password: 'Sumith@123' },
    { KAM: 'Raj Kumar', phoneNumber: '9959977995', password: 'Pard@123' },
    { KAM: 'Poorna Chandher', phoneNumber: '8500941946', password: 'Pista@123' },
    { KAM: "Prithvi", phoneNumber: "9398109426", password: "Prithvi@123" },
    { KAM: "AGRAS KHARA", phoneNumber: "9849116369", password: "Agra@9849" },
    { KAM: "KARYOTICA", phoneNumber: "9100224226", password: "Kary@9100" },
    { KAM: "MEGA KITCHEN", phoneNumber: "9346166679", password: "Mega@9346" },
    { KAM: "SWISS CASTLE", phoneNumber: "6301381368", password: "Swis@6301" },
    { KAM: "SIGNOVA", phoneNumber: "7680944502", password: "Sign@7680" },
    { KAM: "INTRON", phoneNumber: "7780139653", password: "Intr@7780" },
    { KAM: "YUMM KITCHENS", phoneNumber: "8309604314", password: "Yumm@8309" }
];

export const businessData = {
    "Deepak": {
      partners: [
        { businessPartner: "CU0002775", name: "B S R HOTELS", city: "HYDERABAD", buyerGroup: "B S R HOTELS" },
        { businessPartner: "CU0002821", name: "PARADISE FOOD COURT PRIV (BAN)", city: "BANGALORE", buyerGroup: "PARADISE FOOD" },
        { businessPartner: "CU0002823", name: "PARADISE FOOD COURT PRIVA (CHE)", city: "CHENNAI", buyerGroup: "PARADISE FOOD" },
        { businessPartner: "CU0003138", name: "PARADISE FOOD COURT PRIVA (CHE)", city: "CHENNAI", buyerGroup: "PARADISE FOOD" },
        { businessPartner: "CU0003582", name: "ITC Limited", city: "HAJIPU", buyerGroup: "ITC" },
        { businessPartner: "CU0003787", name: "ITC Limited", city: "MUNGER", buyerGroup: "ITC" },
        { businessPartner: "CU0000771", name: "SHREE RADHE DAIRY FARM & (SUR)", city: "SURAT", buyerGroup: "SHREE RADHE" },
        { businessPartner: "CU0002361", name: "SHREE RADHE DAIRY FARM & (SUR)", city: "SURAT", buyerGroup: "SHREE RADHE" },
        { businessPartner: "CU0003084", name: "BLM Industries Private Li (BHO)", city: "BHONGIRI", buyerGroup: "BLM INDUSTRIES" },
        { businessPartner: "CU0003208", name: "PAWANSHREE FOOD INTERNATI (IND)", city: "INDORE", buyerGroup: "PAWANSHREE FOOD" },
        { businessPartner: null, name: "SIGNOVA", city: null, buyerGroup: "SIGNOVA" },
        { businessPartner: null, name: "KARYOTICA", city: null, buyerGroup: "KARYOTICA" },
        { businessPartner: null, name: "INTRON", city: null, buyerGroup: "INTRON" },
        { businessPartner: null, name: "AGRAS KHARA", city: null, buyerGroup: "AGRAS KHARA" },
      ]
    },
    "Joshi": {
      partners: [
        { businessPartner: "CU0000813", name: "SRICHAKRA MILK PRODUCTS L (HYD)", city: "HYDERABAD", buyerGroup: "SRICHAKRA MILK" },
        { businessPartner: "CU0002386", name: "SRICHAKRA MILK PRODUCTS LLP", city: "HYDERABAD", buyerGroup: "SRICHAKRA MILK" },
        { businessPartner: "CU0000427", name: "GRB DAIRY FOODS PVT LTD (HOS)", city: "HOSUR", buyerGroup: "GRB DAIRY" },
        { businessPartner: "CU0000717", name: "HANGYO ICE CREAMS PVT LTD (UDI)", city: "UDIPI", buyerGroup: "HANGYO ICE CREAMS" },
        { businessPartner: "CU0000968", name: "MODEL DAIRY PVT LTD (VIJ)", city: "VIJAYAWADA", buyerGroup: "MODEL DAIRY" },
        { businessPartner: "CU0000987", name: "VEER PACKAGING (AHM)", city: "AHMEDABAD", buyerGroup: "VEER PACKAGING" },
        { businessPartner: "CU0001015", name: "UNIVERSAL AGRO CHEMICAL I (NAG)", city: "NAGPUR", buyerGroup: "UNIVERSAL AGRO" },
        { businessPartner: "CU0001099", name: "VEER PACKAGINGS (AHM)", city: "AHMEDABAD", buyerGroup: "VEER PACKAGING" },
        { businessPartner: "CU0001174", name: "MODERN FOOD PRODUCTS (VIJ)", city: "VIJAYAWADA", buyerGroup: "MODERN FOOD" },
        { businessPartner: "CU0001425", name: "ALSAFA DAIRY MILK (HYD)", city: "HYDERABAD", buyerGroup: "ALSAFA DAIRY MILK" }
      ]
    },
    "Sumith": {
      partners: [
        { businessPartner: "CU0000552", name: "SHIVASHAKTHI BIO TECHNOLO (MAN)", city: "MANERI", buyerGroup: "SHIVASHAKTI" },
        { businessPartner: "CU0000779", name: "SRE RAM AND CO (POL)", city: "POLLACHI", buyerGroup: "SRE RAM CO" },
        { businessPartner: "CU0000820", name: "ARIHANT MILK PRODUCTS (IND)", city: "INDORE", buyerGroup: "ARIHANT MILK PRODUCTS" },
        { businessPartner: "CU0000973", name: "OYSTER EXIM PVT LTD (IND)", city: "INDORE", buyerGroup: "OYSTER EXIM" },
        { businessPartner: "CU0001025", name: "SRI MAHALAKSHMI DAIRY PRIVATE LIMIT", city: "COIMBATORE", buyerGroup: "SRI MAHALAKSHMI" },
        { businessPartner: "CU0001496", name: "ARIHANT MILK PRODUCTS UNI (IND)", city: "INDORE", buyerGroup: "ARIHANT MILK PRODUCTS" },
        { businessPartner: "CU0001600", name: "LAJAWAB INDUSTRIES (NAG)", city: "NAGPUR", buyerGroup: "LAJAWAB INDUSTRIES" },
        { businessPartner: "CU0001647", name: "INDORE SAHAKARI DUGDH SAN (IND)", city: "INDORE", buyerGroup: "SANCHI" },
        { businessPartner: "CU0001649", name: "UJJAIN SAHAKARI DUGDH SAN (UJJ)", city: "UJJAIN", buyerGroup: "SANCHI" },
        { businessPartner: "CU0001657", name: "BUNDELKHAND SAHKARI DUGDH (SAG)", city: "SAGAR", buyerGroup: "SANCHI" }
      ]
    },
    "Prithvi":{
      partners: [
        { businessPartner: null, name: "YUMM KITCHEN", city: null, buyerGroup: "YUMM KITCHEN" },
        { businessPartner: null, name: "MEGA KITCHEN", city: null, buyerGroup: "MEGA KITCHEN" },
        { businessPartner: null, name: "SWISS CASTEL", city: null, buyerGroup: "SWISS CASTEL" }
      ]
    },
    "SIGNOVA": {
      partners: [
          { businessPartner: null, name: "SIGNOVA", city: null, buyerGroup: "SIGNOVA" }
      ]
  },
  "KARYOTICA": {
      partners: [
          { businessPartner: null, name: "KARYOTICA", city: null, buyerGroup: "KARYOTICA" }
      ]
  },
  "INTRON": {
      partners: [
          { businessPartner: null, name: "INTRON", city: null, buyerGroup: "INTRON" }
      ]
  },
  "AGRAS KHARA": {
      partners: [
          { businessPartner: null, name: "AGRAS KHARA", city: null, buyerGroup: "AGRAS KHARA" }
      ]
  },
  "YUMM KITCHEN": {
      partners: [
          { businessPartner: null, name: "YUMM KITCHEN", city: null, buyerGroup: "YUMM KITCHEN" }
      ]
  },
  "MEGA KITCHEN": {
      partners: [
          { businessPartner: null, name: "MEGA KITCHEN", city: null, buyerGroup: "MEGA KITCHEN" }
      ]
  },
  "SWISS CASTEL": {
      partners: [
          { businessPartner: null, name: "SWISS CASTEL", city: null, buyerGroup: "SWISS CASTEL" }
      ]
  }
  };

  export const ordersData = {
    "B S R HOTELS": [
      {
        id: 1,
        buyerGroup: "B S R HOTELS",
        brand: "125 ML_01 THANGEDU (125 ML_01)",
        category: "TW/IML",
        orderQty: 12096,
        balanceQty: 12096,
        dispatchDate: "28-09-2024",
        status: "Pending"
      }
    ],
    "PARADISE FOOD": [
      {
        id: 2,
        buyerGroup: "PARADISE FOOD",
        brand: "04 KG_RAK PARADISE SUPREME BIRYANI (ONLY LID) Rev -01",
        productCode: "7PK10146",
        category: "TW/IML",
        orderQty: 2800,
        balanceQty: 2800,
        dispatchDate: "07-11-2024",
        status: "Pending"
      }
    ],
    "ITC": [
      {
        id: 3,
        buyerGroup: "ITC",
        brand: "2000 ML_RAK AASHIRVAAD SVASTI DAHI (REV-01)",
        category: "IML PAILS",
        orderQty: 3420,
        balanceQty: 3420,
        dispatchDate: "11-10-2024",
        status: "Pending"
      }
    ],
    "SHREE RADHE": [
      {
        id: 4,
        buyerGroup: "SHREE RADHE",
        brand: "05 LTR *Q VASTU AGMARK GHEE MADE FROM COW'S MILK (Rev1)",
        category: "GHEE",
        orderQty: 4994,
        balanceQty: 4994,
        dispatchDate: "30-10-2024",
        status: "Pending"
      }
    ],
    "BLM INDUSTRIES": [
      {
        id: 5,
        buyerGroup: "BLM INDUSTRIES",
        brand: "17 LTR_Q BLM CASHEWS",
        category: "Q PACK",
        orderQty: 3497,
        balanceQty: 3497,
        dispatchDate: "15-09-2024",
        status: "Pending"
      }
    ],
    "PAWANSHREE FOOD": [
      {
        id: 6,
        buyerGroup: "PAWANSHREE FOOD",
        brand: "05 LTR_Q SHREEDHI PREMIUM QUALITY AGMARK GHEE(EMBROSSING LID)",
        category: "Q PACK",
        orderQty: 3982,
        balanceQty: 3982,
        dispatchDate: null,
        status: "Hold by MKTG"
      }
    ],
    "SRICHAKRA MILK": [
      {
        id: 7,
        buyerGroup: "SRICHAKRA MILK",
        brand: "1000 ML SRI CHAKRA HEALTHY & NATURAL CURD (IML)",
        category: "TW/IML",
        orderQty: 3040,
        balanceQty: 3040,
        dispatchDate: "19-10-2024",
        status: "Pending"
      }
    ],
    "GRB DAIRY": [
      {
        id: 8,
        buyerGroup: "GRB DAIRY",
        brand: "05 LTR_Q GRB PURE GHEE(Rev-2)",
        category: "COW GHEE EXPORT",
        orderQty: 2508,
        balanceQty: 2508,
        dispatchDate: "23-08-2024",
        status: "Pending"
      }
    ],
    "HANGYO ICE CREAMS": [
      {
        id: 9,
        buyerGroup: "HANGYO ICE CREAMS",
        brand: "125 ML_01 HANGYO ICE CREAM VANILLA",
        category: "TW/IML",
        orderQty: 16128,
        balanceQty: 16128,
        dispatchDate: "07-10-2024",
        status: "Pending"
      }
    ],
    "MODEL DAIRY": [
      {
        id: 10,
        buyerGroup: "MODEL DAIRY",
        brand: "500 ML_GP MODEL DAIRY GHEE (IML)",
        category: "TW/IML",
        orderQty: 10800,
        balanceQty: 10800,
        dispatchDate: "25-10-2024",
        status: "Pending"
      }
    ],
    "VEER PACKAGING": [
      {
        id: 11,
        buyerGroup: "VEER PACKAGING",
        brand: "05 LTR_Q SHREEMUL COW PURE GHEE (REV-3)",
        category: "Q PACK",
        orderQty: 4004,
        balanceQty: 4004,
        dispatchDate: "18-10-2024",
        status: "Pending"
      }
    ],
    "UNIVERSAL AGRO": [
      {
        id: 12,
        buyerGroup: "UNIVERSAL AGRO",
        brand: "17 LTR_Q GALAXY GOLD ORGANIC GRANULES",
        category: "Q PACK",
        orderQty: 3003,
        balanceQty: 3003,
        dispatchDate: "19-10-2024",
        status: "Pending"
      }
    ],
    "MODERN FOOD": [
      {
        id: 13,
        buyerGroup: "MODERN FOOD",
        brand: "100 GM_RR KWALITY MIXED FRUIT JAM (REV-1)",
        category: "TW/IML",
        orderQty: 71148,
        balanceQty: 71148,
        dispatchDate: "30-09-2024",
        status: "Pending"
      }
    ],
    "ALSAFA DAIRY MILK": [
      {
        id: 14,
        buyerGroup: "ALSAFA DAIRY MILK",
        brand: "500 ML_GP AL SAFA PURE GHEE",
        category: "TW/IML",
        orderQty: 6480,
        balanceQty: 6480,
        dispatchDate: "30-09-2024",
        status: "Pending"
      }
    ],
    "SHIVASHAKTI": [
      {
        id: 15,
        buyerGroup: "SHIVASHAKTI",
        brand: "05 LTR Q_PACK IML Shivashakti Dharani 6.25 KG OFFER",
        category: "Q PACK",
        orderQty: 308,
        balanceQty: 308,
        dispatchDate: null,
        status: "Stock Available-132Qty"
      }
    ],
    "SRE RAM CO": [
      {
        id: 16,
        buyerGroup: "SRE RAM CO",
        brand: "05LTR QPACK IML UDAY KRISHNA AGMARK GHEE REVISION 03",
        category: "Q PACK",
        orderQty: 4994,
        balanceQty: 4994,
        dispatchDate: "25-09-2024",
        status: "Pending"
      }
    ],
    "ARIHANT MILK PRODUCTS": [
      {
        id: 17,
        buyerGroup: "ARIHANT MILK PRODUCTS",
        brand: "05QPACK IML ARIHANTH GHEE REVISION 02",
        category: "Q PACK",
        orderQty: 220,
        balanceQty: 220,
        dispatchDate: null,
        status: "Hold by MKTG"
      }
    ],
    "OYSTER EXIM": [
      {
        id: 18,
        buyerGroup: "OYSTER EXIM",
        brand: "05 LTR PURASURE DESI GHEE WITH AGMARK REVISION 02",
        category: "Q PACK",
        orderQty: 2992,
        balanceQty: 2992,
        dispatchDate: "30-09-2024",
        status: "Pending"
      }
    ],
    "SRI MAHALAKSHMI": [
      {
        id: 19,
        buyerGroup: "SRI MAHALAKSHMI",
        brand: "05 LTR_Q AROMA FRESH CURD (REV-1)",
        category: "Q PACK",
        orderQty: 4994,
        balanceQty: 4994,
        dispatchDate: "05-09-2024",
        status: "Pending"
      }
    ],
    "SANCHI": [
      {
        id: 20,
        buyerGroup: "SANCHI",
        brand: "17 LTR_Q SANCHI GHEE (QR CODE)",
        category: "Q PACK",
        orderQty: 2002,
        balanceQty: 2002,
        dispatchDate: "15-09-2024",
        status: "Pending"
      }
    ],
    "SIGNOVA": [
       {
           id: 21,
           buyerGroup: "SIGNOVA",
           brand: "NO ORDERS",
           category: null,
           orderQty: null,
           balanceQty: null,
           dispatchDate: null,
           status: "Pending"
       }
   ],
   "KARYOTICA": [
       {
           id: 22,
           buyerGroup: "KARYOTICA",
           brand: "1Kg KARYOMAX PLUS (REV-1) (01 LTR)",
           category: "Pails",
           orderQty: 5000,
           balanceQty: null,
           dispatchDate: null,
           status: "Pending"
       }
   ],
   "INTRON": [
       {
           id: 23,
           buyerGroup: "INTRON",
           brand: "NO ORDERS",
           category: null,
           orderQty: null,
           balanceQty: null,
           dispatchDate: null,
           status: "Pending"
       }
   ],
   "AGRAS KHARA": [
       {
           id: 24,
           buyerGroup: "AGRAS KHARA",
           brand: "250 GM_SB AGRA SWEETS BANJARA (250 GM_SB)",
           category: "TW",
           orderQty: 14400,
           balanceQty: 14400,
           dispatchDate: null,
           status: "Stock Ready"
       },
       {
           id: 25,
           buyerGroup: "AGRAS KHARA",
           brand: "750 ML_01 AGRA SWEETS BANJARA (REV-02) (750 ML_01)",
           category: "TW",
           orderQty: 14400,
           balanceQty: 10080,
           status: "Stock Ready"
       }
   ],
   "YUMM KITCHEN": [
       {
           id: 26,
           buyerGroup: "YUMM KITCHEN",
           brand: "NO ORDERS",
           category: null,
           orderQty: null,
           balanceQty: null,
           dispatchDate: null,
           status: "Pending"
       }
   ],
   "MEGA KITCHEN": [
       {
           id: 27,
           buyerGroup: "MEGA KITCHEN",
           brand: "500 ML MEGA KITCHEN (IML) (500 ML_03)",
           category: "TW",
           orderQty: 3520,
           balanceQty: 3520,
           dispatchDate: "08-Nov-24",
           status: "Pending"
       },
       {
           id: 28,
           buyerGroup: "MEGA KITCHEN",
           brand: "750 ML MEGA KITCHEN (IML) (750 ML_01)",
           category: "TW",
           orderQty: 3520,
           balanceQty: 1680,
           dispatchDate: "08-Nov-24",
           status: "Pending"
       }
   ],
   "SWISS CASTEL": [
       {
           id: 29,
           buyerGroup: "SWISS CASTEL",
           brand: "500 ML_03 SWISS CASTLE KAJU HONEY (REV-1) (500 ML_03)",
           category: "TW",
           orderQty: 160,
           balanceQty: 160,
           dispatchDate: null,
           status: "Stock Ready"
       }
   ]
  };
  

  export const productCatalog = {
    "B S R HOTELS": {
      products: [
        { 
          brand: "125 ML_01 THANGEDU (125 ML_01)", 
          category: "TW/IML",
        }
      ]
    },
    "PARADISE FOOD": {
      products: [
        { 
          brand: "04 KG_RAK PARADISE SUPREME BIRYANI (ONLY LID) Rev -01",
          category: "TW/IML",
          productCode: "7PK10146"
        }
      ]
    },
    "ITC": {
      products: [
        { 
          brand: "2000 ML_RAK AASHIRVAAD SVASTI DAHI (REV-01)",
          category: "IML PAILS"
        }
      ]
    },
    "SHREE RADHE": {
      products: [
        { 
          brand: "05 LTR *Q VASTU AGMARK GHEE MADE FROM COW'S MILK (Rev1)",
          category: "GHEE"
        }
      ]
    },
    "BLM INDUSTRIES": {
      products: [
        { 
          brand: "17 LTR_Q BLM CASHEWS",
          category: "Q PACK"
        }
      ]
    },
    "PAWANSHREE FOOD": {
      products: [
        { 
          brand: "05 LTR_Q SHREEDHI PREMIUM QUALITY AGMARK GHEE(EMBROSSING LID)",
          category: "Q PACK"
        }
      ]
    },
    "SRICHAKRA MILK": {
      products: [
        { 
          brand: "1000 ML SRI CHAKRA HEALTHY & NATURAL CURD (IML)",
          category: "TW/IML"
        }
      ]
    },
    "GRB DAIRY": {
      products: [
        { 
          brand: "05 LTR_Q GRB PURE GHEE(Rev-2)",
          category: "COW GHEE EXPORT"
        }
      ]
    },
    "HANGYO ICE CREAMS": {
      products: [
        { 
          brand: "125 ML_01 HANGYO ICE CREAM VANILLA",
          category: "TW/IML"
        }
      ]
    },
    "MODEL DAIRY": {
      products: [
        { 
          brand: "500 ML_GP MODEL DAIRY GHEE (IML)",
          category: "TW/IML"
        }
      ]
    },
    "VEER PACKAGING": {
      products: [
        { 
          brand: "05 LTR_Q SHREEMUL COW PURE GHEE (REV-3)",
          category: "Q PACK"
        }
      ]
    },
    "UNIVERSAL AGRO": {
      products: [
        { 
          brand: "17 LTR_Q GALAXY GOLD ORGANIC GRANULES",
          category: "Q PACK"
        }
      ]
    },
    "MODERN FOOD": {
      products: [
        { 
          brand: "100 GM_RR KWALITY MIXED FRUIT JAM (REV-1)",
          category: "TW/IML"
        }
      ]
    },
    "ALSAFA DAIRY MILK": {
      products: [
        { 
          brand: "500 ML_GP AL SAFA PURE GHEE",
          category: "TW/IML"
        }
      ]
    },
    "SHIVASHAKTI": {
      products: [
        { 
          brand: "05 LTR Q_PACK IML Shivashakti Dharani 6.25 KG OFFER",
          category: "Q PACK"
        }
      ]
    },
    "SRE RAM CO": {
      products: [
        { 
          brand: "05LTR QPACK IML UDAY KRISHNA AGMARK GHEE REVISION 03",
          category: "Q PACK"
        }
      ]
    },
    "ARIHANT MILK PRODUCTS": {
      products: [
        { 
          brand: "05QPACK IML ARIHANTH GHEE REVISION 02",
          category: "Q PACK"
        }
      ]
    },
    "OYSTER EXIM": {
      products: [
        { 
          brand: "05 LTR PURASURE DESI GHEE WITH AGMARK REVISION 02",
          category: "Q PACK"
        }
      ]
    },
    "SRI MAHALAKSHMI": {
      products: [
        { 
          brand: "05 LTR_Q AROMA FRESH CURD (REV-1)",
          category: "Q PACK"
        }
      ]
    },
    "SANCHI": {
      products: [
        { 
          brand: "17 LTR_Q SANCHI GHEE (QR CODE)",
          category: "Q PACK"
        }
      ]
    },
    "SIGNOVA": {
        products: [
            {
                brand: "NO ORDERS",
                category: null
            }
        ]
    },
    "KARYOTICA": {
        products: [
            {
                brand: "1Kg KARYOMAX PLUS (REV-1) (01 LTR)",
                category: "Pails"
            }
        ]
    },
    "INTRON": {
        products: [
            {
                brand: "NO ORDERS",
                category: null
            }
        ]
    },
    "AGRAS KHARA": {
        products: [
            {
                brand: "250 GM_SB AGRA SWEETS BANJARA (250 GM_SB)",
                category: "TW"
            },
            {
                brand: "750 ML_01 AGRA SWEETS BANJARA (REV-02) (750 ML_01)",
                category: "TW"
            }
        ]
    },
    "YUMM KITCHEN": {
        products: [
            {
                brand: "NO ORDERS",
                category: null
            }
        ]
    },
    "MEGA KITCHEN": {
        products: [
            {
                brand: "500 ML MEGA KITCHEN (IML) (500 ML_03)",
                category: "TW"
            },
            {
                brand: "750 ML MEGA KITCHEN (IML) (750 ML_01)",
                category: "TW"
            }
        ]
    },
    "SWISS CASTEL": {
        products: [
            {
                brand: "500 ML_03 SWISS CASTLE KAJU HONEY (REV-1) (500 ML_03)",
                category: "TW"
            }
        ]
    }
  };