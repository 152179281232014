import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid2,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

//
import { uploadImage } from "../../firebase";
import db from "../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import {
  fetchAndParseExcel,
  staticColumns,
  staticColumnsKAM,
  staticColumnsUser,
} from "../../utils";
import { getAuthFromLocal } from "../../utils/storage";
import { useDispatch } from "react-redux";
import { setOrderData } from "../../store/orderSlice";

const DemoTable = () => {
  const [tableData, setTableData] = useState([]);
  const user = getAuthFromLocal();
  const [isLoading, setIsLoading] = useState(true);
  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    loadDataFromFirestore();
  }, []);

  const headerData = () => {
    switch (user?.role) {
      case "SUPER_ADMIN":
        return staticColumns;
      case "KAM":
        return staticColumnsKAM;
      case "USER":
        return staticColumnsUser;
      default:
        return staticColumns;
    }
  };

  const fetchDownloadURL = async () => {
    try {
      // Get the document containing the download URL
      const fileDocRef = doc(db, "files", "orderFile"); // Adjust path if needed
      const fileDoc = await getDoc(fileDocRef);

      if (fileDoc.exists()) {
        const data = fileDoc.data();
        return data.url; // Return the download URL
      } else {
        console.error("No file found in Firestore");
        return null;
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
      return null;
    }
  };

  const loadDataFromFirestore = async (url = "") => {
    const downloadURL = url !== "" ? url : await fetchDownloadURL();

    if (downloadURL) {
      const jsonData = await fetchAndParseExcel(downloadURL);

      if (jsonData && jsonData.length > 1) {
        const bodyData = jsonData.slice(1);
        let rowsData = bodyData.map((row, index) => {
          const rowData = { id: index + 1 };

          jsonData[0].forEach((header, index) => {
            let value = row[index] || "";

            if (
              (header === "dispatchDate" || header === "orderDate") &&
              typeof value === "number" &&
              !isNaN(value) &&
              value > 0
            ) {
              const date = new Date((value - 25569) * 86400 * 1000);
              if (!isNaN(date.getTime())) {
                value = date.toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric" 
                });
              } else {
                value = "";
              }
            }

            if (header === "status" && typeof value === "number" && !isNaN(value) &&value > 0) {
              const date = new Date((value - 25569) * 86400 * 1000);
              if (!isNaN(date.getTime())) {
                value = date.toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric" 
                });
              }
            }

            rowData[header] = value;
          });

          return rowData;
        });

        dispatch(setOrderData(rowsData));

        if (user && user.role === "KAM") {
          rowsData = rowsData.filter(
            (a) => a.KAM && a.KAM.toString().trim() === user.phoneNumber.toString().trim()
          );
        }
        if (user && user.role === "USER") {
          rowsData = rowsData.filter(
            (a) =>
              a.user && a.buyerGroup.toString().trim() === user.username.toString().trim()
          );
        }

        setTableData(rowsData);
        setFilteredRows(rowsData);
        setIsLoading(false);
      } else {
        setFilterValue([]);
        setTableData([]); // Clear data if the file is empty or invalid
      }
    }
  };

  const handleExportToExcel = async (tableData, columns) => {
    const dataWithoutStaticColumns = tableData.map(({ id, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(dataWithoutStaticColumns, {
      header: columns.map((col) => col.field),
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert workbook to an array buffer (binary data)
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Create a Blob from the binary data and use FileSaver to trigger a download
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "orders.xlsx"); // This will trigger the download
    const url = await uploadImage(blob);
    setDocToStoe(url);
  };

  const setDocToStoe = async (url) => {
    const fileDocRef = doc(db, "files", "orderFile"); // Adjust path and document ID as needed
    await setDoc(fileDocRef, {
      url: url,
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setTableData([]);
      setIsLoading(true);
      const url = await uploadImage(file);
      setDocToStoe(url);
      loadDataFromFirestore(url);
    }
  };

  const handleProcessRowUpdate = (newRow) => {
    const updatedRows = tableData.map((row) => {
      if (row.id === newRow.id) {
        return { ...row, ...newRow };
      }
      return row;
    });
    setTableData(updatedRows);
    return newRow;
  };

  const handleApplyFilter = () => {
    const filterNum = filterValue === "" ? 0 : Number(filterValue);
    if (isNaN(filterNum)) return;

    const filtered = tableData.filter((row) => {
      if (!row.balanceQuantity && row.balanceQuantity !== 0) return false;
      const balance = Number(row.balanceQuantity);
      return !isNaN(balance) && balance >= filterNum;
    });

    const sortedFiltered = filtered.sort(
      (a, b) => b.balanceQuantity - a.balanceQuantity
    );
    setFilteredRows(sortedFiltered);
  };

  const handleClearFilter = () => {
    setFilterValue("");
    setFilteredRows(tableData);
  };

  return (
    <div className="p-4 bg-white flex flex-col h-screen">
      <Grid2>
        <Grid2>
          {user && user.role === "USER" && (
            <div className="flex md:hidden gap-2 flex-col  lg:flex-row">
              <Card variant="outlined" className="relative">
                <div className="absolute w-[10px] h-[60px] bg-red-500 right-0" />
                <CardContent>
                  <Typography
                    variant="span"
                    component="div"
                    color="red"
                    className="text-3xl font-bold"
                  >
                    ₹{user?.outstanding} /-
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    Outstanding due on date
                  </Typography>
                  <Typography variant="h5" component="div">
                    {user?.dueDate}
                  </Typography>
                </CardContent>
              </Card>
              <Card variant="outlined" className="flex relative">
                <div className="absolute w-[10px] h-[50px] bg-blue-600 right-0" />
                <CardContent>
                  <Typography sx={{ color: "text.secondary" }}>
                    Message
                  </Typography>
                  <Typography
                    variant="span"
                    component="div"
                    className="font-semibold"
                  >
                    {user?.message}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          )}
        </Grid2>
      </Grid2>
      <div className="flex flex-col lg:flex-row w-full pb-2 lg:gap-2">
        {user && user.role === "USER" && (
          <div className="hidden lg:flex flex-1  gap-2 flex-col  lg:flex-row">
            <Card variant="outlined" className="relative min-w-[300px]">
              <div className="absolute w-[10px] h-[60px] bg-red-500 right-0" />
              <CardContent>
                <Typography
                  variant="span"
                  component="div"
                  color="red"
                  className="text-3xl font-bold"
                >
                  ₹{user?.outstanding} /-
                </Typography>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  Outstanding due on date
                </Typography>
                <Typography variant="h5" component="div">
                  {user?.dueDate}
                </Typography>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              className="relative max-w-[200px] min-w-min lg:min-w-[400px]"
            >
              <div className="absolute w-[10px] h-[60px] bg-blue-600 right-0" />
              <CardContent>
                <Typography sx={{ color: "text.secondary" }}>
                  Message
                </Typography>
                <Typography variant="h6" component="div">
                  {user?.message}
                </Typography>
              </CardContent>
            </Card>
          </div>
        )}

        <div className="flex flex-col lg:flex-row lg:flex-1 gap-2">
          {user && user.role === "SUPER_ADMIN" && (
            <Button variant="contained" component="label">
              Upload File
              <input
                type="file"
                hidden
                onChange={handleFileUpload}
                accept=".xlsx, .xls"
              />
            </Button>
          )}
          {tableData.length !== 0 &&
            (user?.role === "SUPER_ADMIN" || user?.role === "KAM") && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleExportToExcel(tableData, staticColumns)}
              >
                Export to Excel
              </Button>
            )}
        </div>
        <div className="flex flex-col lg:flex-row mt-6 lg:mt-0 gap-2 lg:items-end">
          <TextField
            label="Balance Quantity greater than"
            variant="outlined"
            value={filterValue}
            size="small"
            onChange={(e) => setFilterValue(e.target.value)}
            type="number"
            inputProps={{ min: "0" }}
          />
          <div className="flex justify-between h-[40px]">
            <Button variant="contained" onClick={handleApplyFilter}>
              Apply Filter
            </Button>
            <Button
              variant="outlined"
              onClick={handleClearFilter}
              sx={{ marginLeft: { xs: "5px", sm: 1, md: 1 } }}
            >
              Clear Filter
            </Button>
          </div>
        </div>
      </div>

      <Paper style={{ marginTop: "20px" }}>
        <Box
          sx={{
            height: 600,
            width: "100%",
          }}
        >
          <DataGrid
            pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
            isCellEditable={() => user?.role === "SUPER_ADMIN"}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel: {
                  user: false,
                  KAM: false,
                  superadmin: false,
                },
              },
              sorting: {
                sortModel: [
                  {
                    field: user?.role === "USER" ? "brand" : "buyerGroup",
                    sort: "asc",
                  },
                ],
              },
            }}
            rows={filteredRows}
            columns={headerData()}
            getRowId={(row) => row.id}
            processRowUpdate={handleProcessRowUpdate}
            loading={isLoading}
            sx={{
              "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader": {
                backgroundColor: "#566270",
                color: "white",
                fontSize: "16px",
                fontWeight: "bold !important",
                fontFamily: "Arial, sans-serif !important",

                // Adjust as needed
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold !important",
                fontFamily: "Arial, sans-serif !important", // Apply font family to the header titles
              },
            }}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default DemoTable;
