import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";
import { setRefresh, setUsers } from "../../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getAuthFromLocal } from "../../utils/storage";

const UserTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const { refresh } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [buyers, setBuyers] = useState([]);
  const userData = getAuthFromLocal();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchUserData();
      dispatch(setRefresh(false));
    }
  }, [refresh]);

  const usersColumn = [
    { field: "username", headerName: "User Name", flex: 1 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    { field: "password", headerName: "Password", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
    },
    {
      field: "buyers",
      headerName: "Buyers",
      flex: 1,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          setBuyers(params.row.buyers);
          setOpen(true);
        };

        return typeof params?.row?.buyers === "string" ? (
          <span>{params?.row?.buyers}</span>
        ) : (
          params?.row?.buyers?.length > 0 && (
            <Button
              onClick={onClick}
            >{`Show List (${params?.row?.buyers?.length})`}</Button>
          )
        );
      },
    },
  ];

  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      const usersCollection = collection(db, "users"); // Reference to the 'users' collection
      const querySnapshot = await getDocs(usersCollection); // Fetch all documents
      console.log(userData);
      const usersData = [];
      querySnapshot.forEach((doc) => {
        const user = doc.data();

        if (
          userData.role === "KAM" &&
          userData.buyers?.includes(user.username)
        ) {
          usersData.push({
            ...user,
            id: doc.id,
            buyers:
              user?.buyers && user.buyers.length > 0
                ? user.buyers
                : user.username,
          }); // Collect document ID and data
        }
        if (userData.role === "SUPER_ADMIN" || userData.role === "USER") {
          usersData.push({
            ...user,
            id: doc.id,
            buyers:
              user?.buyers && user.buyers.length > 0
                ? user.buyers
                : user.username,
          }); // Collect document ID and data
        }
      });
      setTableData(usersData);
      dispatch(setUsers(usersData));
    } catch (error) {
      console.error("Error fetching users collection:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const handleProcessRowUpdate = (newRow) => {
    const updatedRows = tableData.map((row) => {
      if (row.id === newRow.id) {
        return { ...row, ...newRow };
      }
      return row;
    });
    setTableData(updatedRows);
    return newRow;
  };

  return (
    <div className="mt-4 h-[600px]">
      <DataGrid
        pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        rows={tableData}
        columns={usersColumn}
        getRowId={(row) => row.id}
        processRowUpdate={handleProcessRowUpdate}
        loading={isLoading}
        sx={{
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader": {
            backgroundColor: "#566270",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important",

            // Adjust as needed
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important", // Apply font family to the header titles
          },
        }}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Buyers List"}</DialogTitle>
        <DialogContent>
          {buyers?.map((item, i) => (
            <div key={i} className="p-2 border-b ">
              {item}
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(UserTable);
