import { createSlice } from "@reduxjs/toolkit";
import { UserMessage, UsersData } from "../type/user";

interface OrderState {
  users: UsersData[];
  message: UserMessage[]
  refresh: boolean;
  buyerRefresh: boolean
}

const initialState: OrderState = {
  users: [],
  message: [],
  refresh: false,
  buyerRefresh: false
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setRefresh: (state, action) => {
        state.refresh = action.payload;
    },
    setBuyerRefresh: (state, action) => {
        state.buyerRefresh = action.payload;
    },
  },
});

// Export the action and reducer
export const { setUsers, setMessage, setRefresh, setBuyerRefresh } = UserSlice.actions;
export default UserSlice.reducer;
