import { createTheme } from '@mui/material';

export const myTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#263959',  // Custom background color for contained buttons
          '&:hover': {
            backgroundColor: '#115293',  // Custom hover color
          },
        },
      },
    },
  },
});