import { configureStore } from '@reduxjs/toolkit';
import apiSlice from './apiSlice';
import OrderSlice from '../store/orderSlice';
import UserSlice from '../store/userSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    order: OrderSlice,
    user: UserSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
