import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { Paper, Typography, Box, Tooltip as MuiTooltip } from '@mui/material';
import { businessData, ordersData } from '../../constant/user';


const getKAMOrderData = (KAM, businessData, ordersData) => {
  if (!businessData[KAM]) return [];

  const buyerGroups = businessData[KAM]?.partners?.map(p => p.buyerGroup);
  let allOrders = [];

  buyerGroups.forEach(group => {
    if (ordersData[group]) {
      const orders = ordersData[group].map(order => ({
        brand: order.brand.split('(')[0].trim(),
        buyerGroup: group,
        orderQty: order.orderQty,
        dispatchQty: order.orderQty - order.balanceQty,
        balanceQty: order.balanceQty,
        category: order.category
      }));
      allOrders = [...allOrders, ...orders];
    }
  });

  return allOrders;
};

const OrderStatus = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [formattedData, setFormattedData] = useState([]);
  const [totalStats, setTotalStats] = useState({
    totalOrders: 0,
    totalDispatch: 0,
    totalBalance: 0
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    setUserInfo(user);

    if (!user?.KAM) {
      const kamOrders = getKAMOrderData('Deepak', businessData, ordersData);
      setFormattedData(kamOrders);

      const stats = kamOrders.reduce((acc, curr) => ({
        totalOrders: acc.totalOrders + curr.orderQty,
        totalDispatch: acc.totalDispatch + curr.dispatchQty,
        totalBalance: acc.totalBalance + curr.balanceQty
      }), { totalOrders: 0, totalDispatch: 0, totalBalance: 0 });

      setTotalStats(stats);
    }
  }, []);

  if (!userInfo) return null;

  return (
    <Box sx={{ padding: 2, width: '100%', boxSizing: 'border-box'}}>
      <Paper elevation={3} sx={{padding:2}}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Order Status - KAM: {userInfo.KAM}
          </Typography>
          <Box sx={{ display: 'flex', gap: 4, mt: 2 }}>
            <MuiTooltip title="Total Order Quantity" arrow placement="top">
              <Typography variant="body2" color="textSecondary">
                Total Orders: {totalStats.totalOrders.toLocaleString()}
              </Typography>
            </MuiTooltip>
            <MuiTooltip title="Total Dispatched Quantity" arrow placement="top">
              <Typography variant="body2" color="textSecondary">
                Total Dispatched: {totalStats.totalDispatch.toLocaleString()}
              </Typography>
            </MuiTooltip>
            <MuiTooltip title="Total Balance Quantity" arrow placement="top">
              <Typography variant="body2" color="textSecondary">
                Total Balance: {totalStats.totalBalance.toLocaleString()}
              </Typography>
            </MuiTooltip>
          </Box>
        </Box>

        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={formattedData} layout='vertical' margin={{ left: 20 }} >
            <XAxis type="number" axisLine={false} tick={false} />
            <YAxis
              width={200}
              padding={{ top: 2, bottom: 2 }}
              tickMargin={10}
              dataKey="brand"
              type="category"
              axisLine={false}
              position="left"

            />
            <Tooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  console.log(payload);

                  return (
                    <Paper
                      elevation={3}
                      sx={{
                        bgcolor: 'background.paper',
                        p: 1.5,
                        border: '1px solid #ccc'
                      }}
                    >
                      <Typography variant="subtitle2" gutterBottom>
                        {label}
                      </Typography>
                      {payload.map((pld, index) => (
                        <Typography
                          key={index}
                          variant="body2"
                          sx={{ color: pld.color }}
                        >
                          {`${pld.name}: `}
                          {pld.value.toLocaleString()} units
                        </Typography>
                      ))}
                    </Paper>
                  );
                }
                return null;
              }}
            />
            <Bar
              dataKey="dispatchQty"
              fill="#7200da"
              stackId="a"
              name="Dispatched"
            >
              <LabelList
                style={{ fill: '#fff' }}
                dataKey="dispatchQty"
                position="insideLeft"
                formatter={(value) => value > 0 ? value.toLocaleString() : ''}
              />
            </Bar>
            <Bar
              dataKey="balanceQty"
              fill="#47b8e0"
              stackId="a"
              name="Balance"
            >
              <LabelList
                style={{ fill: '#000' }}
                dataKey="balanceQty"
                position="insideRight"
                formatter={(value) => value > 0 ? value.toLocaleString() : ''}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>

        <Box sx={{ mt: 2 }}>
          <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mb: 1 }}>
            <MuiTooltip title="Dispatched Orders" arrow placement="top">
              <span style={{ color: '#7200da', marginRight: '8px' }}>■</span>
            </MuiTooltip>
            <MuiTooltip title="Balance Orders" arrow placement="top">
              <span style={{ color: '#47b8e0', marginRight: '8px' }}>■</span>
            </MuiTooltip>
            Dispatched Quantity | Balance Quantity
          </Typography>
        </Box>
      </Paper>
    </Box>

  );
};

export default OrderStatus;