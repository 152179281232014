import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import db, { uploadImage } from "../../firebase";
import { getAuthFromLocal } from "../../utils/storage";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchAndParseExcel, staticColumns } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { setOrderData } from "../../store/orderSlice";
import { toast } from "react-toastify";
import { OrderDataType } from "../../type/order";

interface Order {
  id: number;
  buyerGroup: string;
  brand: string;
  orderQuantity: number;
  remarks: string;
  status: string;
  location: string;
  category: string;
  size: string;
  orderDate: string;
  dispatchedQuantity: string;
  balanceQuantity: string;
  dispatchDate: string;
  user: string;
  KAM: string;
  superadmin: string;
}

interface ExcelRow {
  [key: string]: string | number;
}

const PlaceOrder = () => {
  const user = getAuthFromLocal();
  const [placeOrders, setPlaceOrders] = useState<Order[]>([]);
  const [buyers, setBuyers] = useState<string[]>([]);
  const [brands, setBrands] = useState<string[]>([]);
  const [selectedBuyerGroup, setSelectedBuyerGroup] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { orders } = useSelector((state: any) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    if (orders && orders.length === 0) {
      loadDataFromFirestore();
    }
    fetchBuyers();
  }, []);

  const fetchDownloadURL = async () => {
    try {
      // Get the document containing the download URL
      const fileDocRef = doc(db, "files", "orderFile"); // Adjust path if needed
      const fileDoc = await getDoc(fileDocRef);

      if (fileDoc.exists()) {
        const data = fileDoc.data();
        return data.url; // Return the download URL
      } else {
        console.error("No file found in Firestore");
        return null;
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
      return null;
    }
  };

  const loadDataFromFirestore = async (url: string = "") => {
    const downloadURL = url !== "" ? url : await fetchDownloadURL();

    if (downloadURL) {
      const jsonData = await fetchAndParseExcel(downloadURL);

      if (jsonData && jsonData.length > 1) {
        const bodyData = jsonData.slice(1);
        let rowsData: ExcelRow[] = bodyData.map(
          (row: (string | number)[], index: number) => {
            const rowData: ExcelRow = { id: index + 1 };

            jsonData[0].forEach((header, index) => {
              let value: any = row[index] || "";

              if (
                (header === "dispatchDate" || header === "orderDate") &&
                typeof value === "number" &&
                !isNaN(value) &&
                value > 0
              ) {
                const date = new Date((value - 25569) * 86400 * 1000);
                if (!isNaN(date.getTime())) {
                  value = date.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "2-digit",
                  });
                } else {
                  value = "";
                }
              }

              rowData[header] = value;
            });

            return rowData;
          }
        );
        dispatch(setOrderData(rowsData));
      }
    }
  };

  const fetchBrandByBuyer = async (docId: string) => {
    try {
      const docRef = doc(db, "buyerGroup", docId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setBrands(docSnap.data().brandName);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  const fetchBuyers = async () => {
    const buyerGroupQeury = query(collection(db, "buyerGroup"));
    const querySnapshot = await getDocs(buyerGroupQeury);
    switch (user?.role) {
      case "SUPER_ADMIN":
        if (!querySnapshot.empty) {
          const docs = querySnapshot.docs;
          const groupdata = docs.map((item) => item.id);
          setBuyers(groupdata);
        }
        break;
      case "KAM":
        setBuyers(user?.buyers as string[]);
        break;
      case "USER":
        setBuyers([user?.username as string]);
        break;
      default:
        break;
    }
  };

  const handleAddProduct = (values: any) => {
    if (formik.isValid) {
      const { buyerGroup, brand, orderQuantity, remarks, category } =
        formik.values;
      const userRole = user?.role || "";
      const userPhoneNumber = user?.phoneNumber ? Number(user.phoneNumber) : "";
      const newOrder = {
        id: Date.now(),
        buyerGroup: buyerGroup.trim(),
        brand: brand.trim(),
        orderQuantity: orderQuantity,
        status: remarks,
        location: "",
        category: category.trim(),
        size: "",
        orderDate: new Date().toLocaleDateString(),
        dispatchedQuantity: "",
        balanceQuantity: "",
        dispatchDate: "",
        user: userRole === "USER" ? userPhoneNumber : "",
        KAM: userRole === "KAM" ? userPhoneNumber : "",
        superadmin: userRole === "SUPER_ADMIN" ? userPhoneNumber : "",
      };

      setPlaceOrders((prevOrders: any) => [...prevOrders, newOrder]);
      setOpenDialog(false);
      formik.resetForm();
    }
  };

  const handleSendOrder = () => {
    const xlsxData = [...orders, ...placeOrders];
    handleExportToExcel(xlsxData, staticColumns);
    toast.success("Order send successfully");
  };

  const setDocToStoe = async (url: string) => {
    const fileDocRef = doc(db, "files", "orderFile"); // Adjust path and document ID as needed
    await setDoc(fileDocRef, {
      url: url,
    });
  };

  const handleExportToExcel = async (tableData: OrderDataType[], columns: any) => {
    const dataWithoutStaticColumns = tableData.map(({ id, ...rest }) => rest);

    const worksheet = XLSX.utils.json_to_sheet(dataWithoutStaticColumns, {
      header: columns.map((col: any) => col.field),
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert workbook to an array buffer (binary data)
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Create a Blob from the binary data and use FileSaver to trigger a download
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const url = await uploadImage(blob);
    setDocToStoe(url);
  };

  const handleBuyerGroupChange = (event: any) => {
    const buyerGroup = event.target.value;
    setSelectedBuyerGroup(buyerGroup);
    formik.setFieldValue("buyerGroup", buyerGroup);
    formik.setFieldValue("brand", "");
    formik.setFieldValue("category", "");
    fetchBrandByBuyer(buyerGroup);
  };

  const formik = useFormik({
    initialValues: {
      orderQuantity: 0,
      buyerGroup: "",
      remarks: "",
      brand: "",
      category: "",
    },
    validationSchema: Yup.object({
      orderQuantity: Yup.number()
        .positive()
        .required("Order quantity is required")
        .min(1, "Quantity must be greater than 0"),
      buyerGroup: Yup.string().required("Buyer group is required"),
    }),
    onSubmit: handleAddProduct,
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 60px)",
        padding: 2,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" component="h1" sx={{ mb: 2 }}>
        Place New Order - User
      </Typography>

      {/* Desktop View Form */}
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{ display: { md: "flex", xs: "none" }, gap: 2, mb: 2 }}
      >
        <FormControl required size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Buyer Group</InputLabel>
          <Select
            name="buyerGroup"
            value={formik.values.buyerGroup}
            label="Buyer Group"
            onChange={handleBuyerGroupChange}
            error={
              formik.touched.buyerGroup && Boolean(formik.errors.buyerGroup)
            }
          >
            {buyers?.map((group: any) => (
              <MenuItem key={group} value={group}>
                {group}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl required size="small" sx={{ minWidth: 300 }}>
          <InputLabel>Brand</InputLabel>
          <Select
            name="brand"
            value={formik.values.brand}
            label="Brand"
            onChange={formik.handleChange}
            disabled={!selectedBuyerGroup}
            error={formik.touched.brand && Boolean(formik.errors.brand)}
          >
            {brands?.map((brand: any) => (
              <MenuItem key={brand} value={brand}>
                {brand}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Category"
          name="category"
          value={formik.values.category}
          onChange={formik.handleChange}
          size="small"
          sx={{ minWidth: 120 }}
        />

        <TextField
          label="Quantity"
          name="orderQuantity"
          type="number"
          value={formik.values.orderQuantity}
          onChange={formik.handleChange}
          size="small"
          required
          sx={{ minWidth: 120 }}
          InputProps={{
            inputProps: { min: 1 },
          }}
          error={
            formik.touched.orderQuantity && Boolean(formik.errors.orderQuantity)
          }
          helperText={
            formik.touched.orderQuantity && formik.errors.orderQuantity
          }
        />

        <TextField
          label="Remark"
          name="remarks"
          value={formik.values.remarks}
          onChange={formik.handleChange}
          size="small"
          sx={{ minWidth: 120 }}
        />

        <Button
          variant="contained"
          size="small"
          type="submit"
          color="primary"
          sx={{ minWidth: 60 }}
        >
          Add
        </Button>
      </Box>

      <Box
        sx={{
          display: { md: "none", xs: "flex" },
          justifyContent: "end",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenDialog(true)}
        >
          Add Order
        </Button>
      </Box>

      {/* Modal for Mobile View */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add New Order</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <FormControl required size="small" sx={{ minWidth: 200 }}>
              <InputLabel>Buyer Group</InputLabel>
              <Select
                name="buyerGroup"
                value={formik.values.buyerGroup}
                label="Buyer Group"
                onChange={handleBuyerGroupChange}
                error={
                  formik.touched.buyerGroup && Boolean(formik.errors.buyerGroup)
                }
              >
                {buyers?.map?.((group: any) => (
                  <MenuItem key={group} value={group}>
                    {group}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl required size="small" sx={{ minWidth: 300 }}>
              <InputLabel>Brand</InputLabel>
              <Select
                name="brand"
                value={formik.values.brand}
                label="Brand"
                onChange={formik.handleChange}
                disabled={!selectedBuyerGroup}
                error={formik.touched.brand && Boolean(formik.errors.brand)}
              >
                {brands?.map((brand: any) => (
                  <MenuItem key={brand} value={brand}>
                    {brand}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Category"
              name="category"
              value={formik.values.category}
              onChange={formik.handleChange}
              size="small"
              sx={{ minWidth: 120 }}
              error={formik.touched.category && Boolean(formik.errors.category)}
              helperText={formik.touched.category && formik.errors.category}
            />

            <TextField
              label="Quantity"
              name="orderQuantity"
              type="number"
              value={formik.values.orderQuantity}
              onChange={formik.handleChange}
              size="small"
              required
              sx={{ minWidth: 120 }}
              InputProps={{
                inputProps: { min: 1 },
              }}
              error={
                formik.touched.orderQuantity &&
                Boolean(formik.errors.orderQuantity)
              }
              helperText={
                formik.touched.orderQuantity && formik.errors.orderQuantity
              }
            />

            <TextField
              label="Remark"
              name="remarks"
              type="number"
              value={formik.values.remarks}
              onChange={formik.handleChange}
              size="small"
              sx={{ minWidth: 120 }}
              InputProps={{
                inputProps: { min: 1 },
              }}
              error={formik.touched.remarks && Boolean(formik.errors.remarks)}
              helperText={formik.touched.remarks && formik.errors.remarks}
            />

            <Button
              variant="contained"
              size="small"
              color="primary"
              type="submit"
              sx={{ minWidth: 60 }}
            >
              Add
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Table for order list */}
      <TableContainer
        component={Paper}
        sx={{ flexGrow: 1, maxHeight: "50vh", overflowY: "auto" }}
      >
        <Table size="small" aria-label="order table">
          <TableHead>
            <TableRow>
              <TableCell>Buyer Group</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {placeOrders.length > 0 ? (
              placeOrders.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell>{row?.buyerGroup}</TableCell>
                  <TableCell>{row.brand}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>{row.orderQuantity}</TableCell>
                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No products added yet
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={placeOrders.length === 0}
          onClick={handleSendOrder}
        >
          Send Order
        </Button>
      </Box>
    </Box>
  );
};

export default PlaceOrder;
