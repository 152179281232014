import { GridColDef } from '@mui/x-data-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { staticColumns } from '../utils';

interface ExcelRow {
    [key: string]: string | number;
}

interface OrderState {
    orders: ExcelRow[];
    columns: GridColDef[]
}

const initialState: OrderState = {
    orders: [],
    columns: staticColumns
};

const OrderSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        setOrderData: (state, action: PayloadAction<ExcelRow[]>) => {
            state.orders = action.payload;
        },
    },
});

// Export the action and reducer
export const { setOrderData } = OrderSlice.actions;
export default OrderSlice.reducer;
