import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import ROUTE_URLS, { BASE_URL } from "./core/routes";
import RouteAuthGuardRestrictLoggedUser from "./component/RouteAuthGuardRestrictLoggedUser";
import RouteAuthGuard from "./component/RouteAuthGuard";
import Layout from "./component/Layout";
import OrderStatus from "./component/atom/OrderStatus";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import User from "./pages/user/User";
import Demo from "./pages/demo/Demo";
import PlaceOrder from "./pages/placeOrder/placeOrder";
import { Analytics } from '@vercel/analytics/react';

// import '@ag-grid-community/styles/ag-grid.css';        // Core grid CSS
// import '@ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS (or any other theme you are using)
// import AGDemo from "./pages/ag-dmeo";

const App = () => {
  return (

    <>
      <Routes>
        <Route
          path={BASE_URL}
          element={
            <RouteAuthGuard>
              <Layout />
            </RouteAuthGuard>
          }>
          <Route path={ROUTE_URLS.HOME} element={<Demo />} />
          <Route path={ROUTE_URLS.ORDERS} element={<PlaceOrder />} />
          <Route path={ROUTE_URLS.ORDER_STATUS} element={<OrderStatus />} />
          <Route path={ROUTE_URLS.USERS} element={<User />} />
          {/* <Route path={ROUTE_URLS.AG_DEMO} element={<AGDemo />} /> */}
        

        </Route>

        <Route
          path={ROUTE_URLS.LOGIN}
          element={
            <RouteAuthGuardRestrictLoggedUser>
              <Login />
            </RouteAuthGuardRestrictLoggedUser>
          }
        />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Analytics />
    </>
  );
};

export default App;
