import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import ROUTE_URLS from "../core/routes";

interface RouteAuthGuardRestrictLoggedUserProps {
  children: ReactNode;
}

const RouteAuthGuardRestrictLoggedUser: React.FC<RouteAuthGuardRestrictLoggedUserProps> = ({ children }) => {
  const currentUser = localStorage.getItem("currentUser");
  let accessToken: string | undefined = undefined;
  if (currentUser) {
    try {
      const parsedUser = JSON.parse(currentUser);
      accessToken = parsedUser?.accessToken;
    } catch (error) {
      console.error("Failed to parse currentUser:", error);
    }
  }
  if (accessToken) {
    return <Navigate to={ROUTE_URLS.HOME} replace />;
  }

  return <>{children}</>;
};

export default RouteAuthGuardRestrictLoggedUser;
